<template>
    <div class="user-info" title="Uitloggen">
        <div class="avatar" @click="logout()">
            <nuxt-img ref="avatar" :src="usr?.picture" alt="User" @error="onError" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import type { User } from '~/types';

    const avatar = ref<HTMLImageElement>();
    const { user, clear } = useUserSession();
    const router = useRouter();

    const usr = computed(() => user.value as User);

    const logout = async () => {
        await clear();

        router.push('/');
    };

    const onError = () => {
        if (avatar.value) {
            avatar.value.src = '/profile.svg';
        }
    };
</script>

<style scoped lang="scss">
    .user-info {
        display: flex;
        font-size: 1.2rem;
        gap: 0.5rem;
        height: 100%;
        justify-content: end;
        position: relative;

        .avatar {
            background-color: hsl(var(--primary-100) / 80%);
            border: 2px solid hsl(var(--primary-100));
            border-radius: 0.5rem;
            cursor: pointer;
            display: block;
            height: 3rem;
            overflow: hidden;
            width: 3rem;

            img {
                object-fit: cover;
                width: 100%;
            }
        }

        .name {
            display: flex;
            gap: 0.5rem;

            span {
                &:first-child {
                    font-style: italic;
                }

                &:nth-child(2) {
                    font-weight: bold;
                }
            }
        }

        .storage-provider {
            background-color: hsl(var(--grey-200));
            border-radius: 50%;
            bottom: -0.75rem;
            box-shadow: hsl(var(--primary-900) / 25%) -2px 0 3px;
            height: 1.8rem;
            padding: 0.1rem;
            position: absolute;
            right: -0.75rem;
            width: 1.8rem;
        }
    }
</style>
