<template>
    <span :class="classes">
        <slot></slot>
    </span>
</template>

<script lang="ts" setup>
    export type IconVariant = '' | 'empty' | 'filled' | 'thin' | 'slim' | 'bold';

    interface Props {
        variant?: IconVariant;
    }

    const { variant = '' } = defineProps<Props>();

    const classes = computed(() => {
        return {
            'vz-icon': true,
            'mod-filled': variant === 'filled',
            'mod-slim': variant === 'slim',
            'mod-bold': variant === 'bold',
            'mod-thin': variant === 'thin',
        };
    });

    defineOptions({
        name: 'VZIcon',
    });
</script>

<style lang="scss">
    .vz-icon {
        display: inline-block;

        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: 'Material Symbols Outlined';
        font-size: var(--icon-size, var(--icon-m));

        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-variation-settings:
            'FILL' 1,
            'wght' 700,
            'GRAD' 200,
            'opsz' 48;
        speak: none;
        text-transform: none;

        &.mod-empty {
            font-variation-settings:
                'FILL' 0,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48;
        }

        &.mod-filled {
            font-variation-settings:
                'FILL' 1,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48;
        }

        &.mod-thin {
            font-variation-settings:
                'FILL' 0,
                'wght' 200,
                'GRAD' 0,
                'opsz' 48;
        }

        &.mod-slim {
            font-variation-settings:
                'FILL' 0,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48;
        }

        &.mod-bold {
            font-variation-settings:
                'FILL' 0,
                'wght' 600,
                'GRAD' 0,
                'opsz' 48;
        }
    }
</style>
