<template>
    <nuxt-loading-indicator />
    <nuxt-pwa-manifest />
    <vz-appbar />
    <nuxt-layout>
        <nuxt-page />
    </nuxt-layout>
    <vz-toast position="bottom-right" />
</template>

<style lang="scss">
    #mijn-epd {
        display: grid;
        grid-template: 100px auto minmax(0, 1fr) / 1fr;
        grid-template-areas:
            'header'
            'breadcrumbs'
            'main';
        height: 100%;
        width: 100%;
    }

    header {
        grid-area: header;
    }

    main {
        grid-area: main;
    }

    footer {
        grid-area: footer;
    }
</style>
