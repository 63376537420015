import { default as contactdhTxi3yPiMMeta } from "/builds/void-zero/clients/mijn-epd/pages/contact.vue?macro=true";
import { default as _91fileId_93bGjhcd1rPEMeta } from "/builds/void-zero/clients/mijn-epd/pages/dashboard/category/[id]/[fileId].vue?macro=true";
import { default as indexuKxpLZpncyMeta } from "/builds/void-zero/clients/mijn-epd/pages/dashboard/category/[id]/index.vue?macro=true";
import { default as indexJoOgwjKZPsMeta } from "/builds/void-zero/clients/mijn-epd/pages/dashboard/index.vue?macro=true";
import { default as disclaimerFXkmEAZRzuMeta } from "/builds/void-zero/clients/mijn-epd/pages/disclaimer.vue?macro=true";
import { default as indexc4HmQY2RrYMeta } from "/builds/void-zero/clients/mijn-epd/pages/index.vue?macro=true";
import { default as privacyslKCIY8Wm8Meta } from "/builds/void-zero/clients/mijn-epd/pages/privacy.vue?macro=true";
import { default as component_45stubA1MhVolwHqMeta } from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.2_encoding@0.1.13_ybdqk7g6d4i3uz7fuwczigkhzy/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubA1MhVolwHq } from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.2_encoding@0.1.13_ybdqk7g6d4i3uz7fuwczigkhzy/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "contact___nl",
    path: "/contact/",
    component: () => import("/builds/void-zero/clients/mijn-epd/pages/contact.vue")
  },
  {
    name: "dashboard-category-id-fileId___nl",
    path: "/dashboard/category/:id()/:fileId()/",
    meta: _91fileId_93bGjhcd1rPEMeta || {},
    component: () => import("/builds/void-zero/clients/mijn-epd/pages/dashboard/category/[id]/[fileId].vue")
  },
  {
    name: "dashboard-category-id___nl",
    path: "/dashboard/category/:id()/",
    meta: indexuKxpLZpncyMeta || {},
    component: () => import("/builds/void-zero/clients/mijn-epd/pages/dashboard/category/[id]/index.vue")
  },
  {
    name: "dashboard___nl",
    path: "/dashboard/",
    meta: indexJoOgwjKZPsMeta || {},
    component: () => import("/builds/void-zero/clients/mijn-epd/pages/dashboard/index.vue")
  },
  {
    name: "disclaimer___nl",
    path: "/disclaimer/",
    component: () => import("/builds/void-zero/clients/mijn-epd/pages/disclaimer.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/builds/void-zero/clients/mijn-epd/pages/index.vue")
  },
  {
    name: "privacy___nl",
    path: "/privacy/",
    component: () => import("/builds/void-zero/clients/mijn-epd/pages/privacy.vue")
  },
  {
    name: component_45stubA1MhVolwHqMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubA1MhVolwHq
  },
  {
    name: component_45stubA1MhVolwHqMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubA1MhVolwHq
  },
  {
    name: component_45stubA1MhVolwHqMeta?.name,
    path: "/nl-sitemap.xml",
    component: component_45stubA1MhVolwHq
  }
]