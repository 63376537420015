const AuthenticatedRoutePrefixes = ['/dashboard'];

export default defineNuxtRouteMiddleware((to) => {
    const { loggedIn } = useUserSession();

    if (to.path === '/' && loggedIn.value) return navigateTo('/dashboard');

    if (AuthenticatedRoutePrefixes.some((p) => to.path.startsWith(p))) {
        if (!loggedIn.value) return navigateTo('/');
    }
});
