<template>
    <teleport :to="appendTo">
        <div :class="classes" ref="container">
            <transition-group name="vz-toast" tag="div" @enter="onEnter" @leave="onLeave">
                <vz-toast-message
                    v-for="(toast, index) of toasts"
                    :key="toast.id"
                    :toast="toast"
                    @close="removeToast($event)" />
            </transition-group>
        </div>
    </teleport>
</template>

<script setup lang="ts">
    import type { ToastPosition } from '~/types/Toast';

    type Props = {
        position?: ToastPosition;
        appendTo?: string;
    };

    const { position = 'bottom-right', appendTo = '#teleports' } = defineProps<Props>();

    const toastStore = useToast();
    const toasts = toastStore.toasts;

    const removeToast = ({ id, type }: { id: string; type: string }) => {
        toastStore.removeToast(id);
    };

    const classes = computed(() => {
        const [vertical, horizontal] = position.split('-');

        return {
            'vz-toast-container': true,
            [`vz-toast-container-${vertical}`]: true,
            [`vz-toast-container-${horizontal}`]: true,
        };
    });

    const onEnter = () => {};
    const onLeave = () => {};
</script>

<style scoped lang="scss">
    .vz-toast-enter-from {
        max-height: 0;
        opacity: 0;
        transform: translateY(50%);
    }

    .vz-toast-leave-from {
        max-height: 500px;
    }

    .vz-toast-container .vz-toast.vz-toast-leave-to {
        margin-bottom: 0;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    }

    .vz-toast-enter-active {
        transition:
            transform 0.3s,
            opacity 0.3s;
    }

    .vz-toast-leave-active {
        transition:
            max-height 0.75s cubic-bezier(0, 1, 0, 1),
            opacity 0.5s,
            margin-bottom 0.5s;
    }

    .vz-toast-container {
        position: absolute;
        white-space: pre-line;
        width: calc(100% - 20px);
        word-break: break-word;
        z-index: 9999;

        @include breakpoint(medium) {
            width: var(--vz-toast-width, 25rem);
        }

        &-top {
            bottom: auto;
            top: 10px;
        }

        &-bottom {
            bottom: 10px;
            top: auto;
        }

        &-left {
            left: 10px;
            right: auto;
        }

        &-right {
            left: auto;
            right: 10px;
        }

        &-center {
            left: 50%;
            transform: translateX(-50%);
        }

        &-middle {
            top: 50%;
            transform: translateY(-50%);
        }
    }
</style>
