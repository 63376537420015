<template>
    <div class="hamburger" v-bind="$attrs" :class="{ animate: animate }">
        <svg
            ref="svg"
            class="hb"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 10"
            stroke="#eee"
            stroke-width=".6"
            fill="rgba(0,0,0,0)"
            stroke-linecap="round"
            style="cursor: pointer">
            <path d="M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7">
                <animate
                    dur="0.2s"
                    attributeName="d"
                    values="M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7;M3,3L5,5L7,3M5,5L5,5M3,7L5,5L7,7"
                    fill="freeze"
                    begin="start.begin" />
                <animate
                    dur="0.2s"
                    attributeName="d"
                    values="M3,3L5,5L7,3M5,5L5,5M3,7L5,5L7,7;M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7"
                    fill="freeze"
                    begin="reverse.begin" />
            </path>
            <rect width="10" height="10" stroke="none">
                <animate dur="2s" id="reverse" attributeName="width" />
            </rect>
            <rect width="10" height="10" stroke="none">
                <animate
                    dur="0.001s"
                    id="start"
                    attributeName="width"
                    values="10;0"
                    fill="freeze" />
                <animate dur="0.001s" attributeName="width" values="0;10" fill="freeze" />
            </rect>
        </svg>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref, watchEffect } from 'vue';

    type Props = {
        state: 'open' | 'closed';
    };

    const { state = 'closed' } = defineProps<Props>();

    const svg = ref<SVGElement | null>(null);
    const animate = ref(false);
    const mounted = ref(false);

    onMounted(() => {
        mounted.value = true;
    });

    const triggerAnimation = (reverse = false) => {
        if (!mounted.value) return;

        const selector = reverse
            ? 'animate[begin="reverse.begin"]'
            : 'animate[begin="start.begin"]';

        const animateElements: NodeListOf<SVGAnimateElement> | undefined =
            svg.value?.querySelectorAll(selector);

        animateElements?.forEach((el) => {
            el.beginElement();
        });
    };

    watchEffect(() => {
        animate.value = state === 'closed';
        triggerAnimation(animate.value);
    });
</script>

<style scoped lang="scss">
    .hamburger {
        @include breakpoint('large') {
            opacity: 0;
            pointer-events: none;
        }

        svg {
            height: 40px;
            -webkit-tap-highlight-color: transparent;
            vertical-align: middle;
            width: 40px;
        }
    }
</style>
