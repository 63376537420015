<template>
    <div class="vz-toast" :class="classes" role="alert" aria-atomic="true" aria-live="assertive">
        <div class="vz-toast-content">
            <slot v-if="$slots.icon || toast.icon" name="icon">
                <div class="vz-toast-icon">
                    <vz-icon v-if="toast.icon" aria-hidden="true" variant="slim">
                        {{ toast.icon }}
                    </vz-icon>
                </div>
            </slot>
            <div class="vz-toast-message">
                <slot name="message">
                    <span class="vz-toast-summary">{{ toast.title }}</span>
                    <div v-if="toast.message" class="vz-toast-detail">{{ toast.message }}</div>
                </slot>
                <div v-if="toast.actions.length" :class="actionClasses">
                    <vz-button
                        v-for="action in toastActions"
                        :key="action.label"
                        small
                        :class="{ text: action.type === 'link' }"
                        @click="performAction($event, action)">
                        {{ action.label }}
                    </vz-button>
                </div>
            </div>
            <slot v-if="isClosable" name="icon">
                <div
                    class="vz-toast-icon vz-close-icon"
                    @click="close({ id: toast.id, type: 'user-action' })">
                    <vz-icon aria-hidden="true" variant="slim"> close </vz-icon>
                </div>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
    import type { Action, Toast } from '~/types/Toast';

    type CloseParams = {
        id: string;
        type: 'life-end' | 'user-action';
    };

    type Props = {
        toast: Toast;
    };

    const { toast } = defineProps<Props>();
    const emit = defineEmits(['close']);

    onMounted(() => {
        if (toast.duration) {
            setTimeout(() => {
                close({ id: toast.id, type: 'life-end' });
            }, toast.duration);
        }
    });

    const classes = computed(() => {
        return {
            'vz-toast': true,
            [`vz-toast-${toast.severity ?? 'secondary'}`]: true,
        };
    });

    const toastActions = computed(() => {
        return toast.actions.toSorted((a, b) => {
            let sortA = a.order ?? 99;
            let sortB = b.order ?? 99;

            if (sortA < 0) sortA = 99 - sortA;
            if (sortB < 0) sortB = 99 - sortB;

            return sortA - sortB;
        });
    });

    const actionClasses = computed(() => {
        return {
            'vz-toast-actions': true,
            'vz-toast-actions-align-start': toast.alignActions === 'start',
            'vz-toast-actions-align-center': toast.alignActions === 'center',
            'vz-toast-actions-align-end': toast.alignActions === 'end' || !toast.alignActions,
        };
    });

    const close = (params: CloseParams) => {
        emit('close', params);
    };

    const performAction = (event: Event, action: Action) => {
        const actionClose = () => close({ id: toast.id, type: 'user-action' });
        action.click(actionClose);
    };

    const isClosable = computed(() => {
        return !toast.duration || toast.closable;
    });

    defineExpose({
        close,
    });
</script>

<style lang="scss">
    :root {
        --vz-toast-width: 30rem;
    }

    .vz-toast {
        --vz-toast-shadow: 0 4px 8px 0
            color-mix(in srgb, var(--toast-severity-color), transparent 90%);
        --vz-toast-border-color: var(--toast-border-color);
        --vz-toast-background: color-mix(in srgb, var(--toast-background), transparent 30%);
        --vz-toast-color: var(--toast-severity-color);
        --vz-toast-detail-color: var(--toast-detail-color);
        --vz-toast-icon-size: var(--toast-icon-size, 1.25rem);
        --vz-toast-icon-color: var(--toast-icon-color, var(--vz-toast-color));
        --vz-toast-transition-duration: 300ms;
        --vz-toast-close-button-width: 1.5rem;
        --vz-toast-close-button-height: 1.5rem;
        --vz-toast-close-button-border-radius: 3px;

        // Buttons
        --vz-toast-button-color: var(
            --toast-button-color,
            color-mix(in srgb, var(--vz-toast-background), #ffffff 70%)
        );
        --vz-toast-button-background: var(--vz-toast-color);
        --vz-toast-button-hover-background-modifier: color-mix(
            in srgb,
            var(--vz-toast-button-background),
            var(--vz-toast-button-color) 30%
        );
        --vz-toast-button-border-color: var(--toast-text-button-color, var(--vz-toast-color));
        --vz-toast-text-button-hover-modifier: var(
            --toast-text-button-hover-modifier,
            var(--vz-toast-button-hover-background-modifier)
        );

        backdrop-filter: blur(10px);
        background: var(--vz-toast-background);
        border-color: var(--vz-toast-border-color);
        border-radius: var(--vz-toast-border-radius, 6px);
        border-style: solid;
        border-width: 1px;
        box-shadow: var(--vz-toast-shadow);
        color: var(--vz-toast-color);
        margin-block-end: 1rem;
        position: relative;
        user-select: none;

        .vz-toast-content {
            align-items: flex-start;
            display: flex;
            gap: var(--vz-toast-content-gap, 0.5rem);
            padding: var(--vz-toast-content-padding, 0.75rem);

            .vz-toast-icon {
                align-items: center;
                background: transparent;
                border: none;
                color: var(--vz-toast-icon-color);
                display: flex;
                flex-shrink: 0;
                height: var(--vz-toast-close-button-height);
                justify-content: center;
                line-height: 1.125;
                outline-color: transparent;
                overflow: hidden;
                padding: 0;
                position: relative;
                user-select: none;
                width: var(--vz-toast-close-button-width);

                .vz-icon {
                    display: flex;
                    font-size: var(--vz-toast-icon-size);
                    height: var(--vz-toast-icon-size);
                    width: var(--vz-toast-icon-size);
                }

                &.vz-close-icon {
                    background: transparent;
                    border-radius: var(--vz-toast-close-button-border-radius);
                    cursor: pointer;
                    height: var(--vz-toast-close-button-height);
                    transition:
                        background var(--vz-toast-transition-duration),
                        color var(--vz-toast-transition-duration),
                        outline-color var(--vz-toast-transition-duration),
                        box-shadow var(--vz-toast-transition-duration);
                    width: var(--vz-toast-close-button-width);

                    &:hover {
                        background-color: color-mix(
                            in srgb,
                            var(--vz-toast-border-color),
                            transparent 50%
                        );
                    }
                }
            }

            .vz-toast-message {
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                gap: var(--vz-toast-message-gap, 0.5rem);

                .vz-toast-summary {
                    font-size: var(--vz-toast-summary-font-size, 1rem);
                    font-weight: var(--vz-toast-summary-font-weight, 600);

                    &:only-child {
                        font-weight: 200;
                    }
                }

                .vz-toast-detail {
                    color: var(--vz-toast-detail-color);
                }

                .vz-toast-actions {
                    display: flex;
                    gap: var(--vz-toast-actions-gap, 0.5rem);
                    margin-block-start: var(--vz-toast-actions-margin-block-start, 0.5rem);

                    &.vz-toast-actions-align-start {
                        justify-content: flex-start;
                    }

                    &.vz-toast-actions-align-center {
                        justify-content: center;
                    }

                    &.vz-toast-actions-align-end {
                        justify-content: flex-end;
                    }

                    .vz-button {
                        background: transparent;
                        color: var(--vz-toast-button-color);
                        padding: 8px 12px;

                        &.text {
                            color: var(--vz-toast-text-button-color);
                            transition: color 300ms ease-in-out;

                            &:hover {
                                color: var(--vz-toast-text-button-hover-modifier);
                            }
                        }

                        &:not(.text) {
                            background: var(--vz-toast-button-background);
                            border: 1px solid var(--vz-toast-button-border-color);

                            &:hover {
                                background: var(--vz-toast-button-hover-background-modifier);
                            }
                        }
                    }
                }
            }
        }

        &::before {
            background: linear-gradient(
                to right,
                transparent 0%,
                var(--vz-toast-color) 50%,
                transparent 100%
            );
            content: '';
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            top: -1px;
            width: 100%;
        }

        strong {
            display: block;
            margin-bottom: 0.5rem;
        }

        p {
            margin-block-end: 0;
        }

        &.vz-toast-info {
            --toast-severity-color: #2563eb;
            --toast-background: #eff6ff;
            --toast-border-color: #bfdbfe;
            --toast-shadow: #2563eb;
        }

        &.vz-toast-success {
            --toast-severity-color: #16a34a;
            --toast-background: #f0fdf4;
            --toast-border-color: #93ddad;
            --toast-shadow: #22c55e;
        }

        &.vz-toast-warning {
            --toast-severity-color: #ca8a04;
            --toast-background: #fefce8;
            --toast-border-color: #dcccaa;
            --toast-shadow: #eab308;
            --toast-detail-color: #423821;
        }

        &.vz-toast-error {
            --toast-severity-color: #dc2626;
            --toast-background: #fef2f2;
            --toast-border-color: #ecb8b8;
            --toast-shadow: #dc2626;
        }

        &.vz-toast-secondary {
            --toast-severity-color: #475569;
            --toast-background: #f1f5f9;
            --toast-border-color: #b6bcc4;
            --toast-shadow: #64748b;
            --toast-text-button-hover-modifier: #a1a1a1;
        }

        &.vz-toast-contrast {
            --toast-severity-color: #f8fafc;
            --toast-background: #0f172a;
            --toast-border-color: #020617;
            --toast-shadow: #020617;
            --toast-button-color: #0f172a;
            --toast-text-button-hover-modifier: #a1a1a1;
        }
    }
</style>
