import type { PartialBy } from '~/types';
import type { Toast } from '~/types/Toast';

export const useToast = defineStore('toasts', () => {
    const toasts = ref<Toast[]>([]);
    const toastId = ref(0);

    const addToast = (toast: PartialBy<Toast, 'id'>) => {
        if (toast.id === undefined) {
            toast.id = `toast-${toastId.value++}`;
        }

        toasts.value.push({ ...toast, id: toast.id });
    };

    const removeToast = (id: string) => {
        const index = toasts.value.findIndex((toast) => toast.id === id);
        if (index > -1) {
            toasts.value.splice(index, 1);
        }
    };

    return {
        toasts,
        addToast,
        removeToast,
    };
});
