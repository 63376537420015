<template>
    <header class="vz-appbar">
        <div class="content">
            <mobile-menu @click="toggleMenu" :state="menuState" v-if="loggedIn" />
            <div class="title"><span class="mijn">Mijn</span><span class="epd">EPD</span></div>
            <user-info v-if="loggedIn" />
        </div>
    </header>
</template>

<script lang="ts" setup>
    import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';

    const { loggedIn } = useUserSession();

    const menuState = ref<'open' | 'closed'>('closed');
    const observer = ref<MutationObserver>();

    onMounted(async () => {
        observer.value = new MutationObserver((mutations) => {
            for (const m of mutations) {
                const newValue = (m.target as HTMLBodyElement).getAttribute('class');
                nextTick(() => {
                    onClassChange(newValue);
                });
            }
        });

        const body = document.querySelector('body') as Node;

        observer.value.observe(body, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ['class'],
        });
    });

    onBeforeUnmount(() => {
        observer.value?.disconnect();
    });

    const onClassChange = (classAttrValue: string | null) => {
        if (classAttrValue) {
            const classlist = classAttrValue.split(' ');

            if (classlist.includes('menu-open')) {
                menuState.value = 'open';
            } else {
                menuState.value = 'closed';
            }
        } else {
            menuState.value = 'closed';
        }
    };

    watchEffect(() => {
        if (!document?.body) return;
        if (menuState.value === 'open') {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    });

    const toggleMenu = () => {
        menuState.value = menuState.value === 'open' ? 'closed' : 'open';
    };

    defineOptions({
        name: 'vz-appbar',
    });
</script>

<style scoped lang="scss">
    .vz-appbar {
        --navbar-padding: 1rem;

        align-items: center;
        background-color: hsl(var(--primary-700));
        color: hsl(var(--white));
        display: grid;
        grid-template-columns:
            [nav-start] var(--navbar-padding)
            [content-start] 1fr [content-end]
            var(--navbar-padding) [nav-end];
        grid-template-rows: [content-start] 1fr [content-end];
        position: relative;
        width: 100%;
        z-index: 10;

        @include breakpoint(medium) {
            --navbar-padding: 2rem;
        }

        .content {
            align-items: center;
            display: flex;
            grid-area: content;
            justify-content: space-between;
            text-align: center;

            .title {
                color: hsl(var(--white));
                flex-grow: 1;
                font-size: 2.5rem;
                user-select: none;

                .mijn {
                    font-style: italic;
                }

                .epd {
                    font-style: bold;
                }
            }
        }
    }

    .vz-appbar a {
        color: white;
        transition: color 300ms;
    }

    .vz-appbar a:hover,
    .vz-appbar a:focus {
        color: grey;
    }

    .mijn-epd {
        font-size: 2.5rem;
        font-weight: 150;
        grid-area: title;
        justify-self: center;
        vertical-align: middle;
    }

    .teruglink {
        display: block;
        font-weight: 150;
        grid-area: terug;
        margin-inline-start: 1rem;
        text-decoration: none;
    }

    .infoicon {
        display: block;
        grid-area: info;
        justify-self: end;
        margin-inline-end: 1rem;
        text-decoration: none;
    }

    .user-info {
        grid-area: profile;
    }
</style>
