import revive_payload_client_7jS4uL694P from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.2_encoding@0.1.13_ybdqk7g6d4i3uz7fuwczigkhzy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EtG5wnnFqT from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.2_encoding@0.1.13_ybdqk7g6d4i3uz7fuwczigkhzy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_gbQjmv19h6 from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.2_encoding@0.1.13_ybdqk7g6d4i3uz7fuwczigkhzy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_dDebdgUmgI from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.18.0_vite@5.4.8_@types+node@22.7.2_sass@1.79._sjs6bmqwsdtjaiii3uazpdzkfm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_wmaNbmF88K from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.2_encoding@0.1.13_ybdqk7g6d4i3uz7fuwczigkhzy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_fmqO5o7ErX from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.2_encoding@0.1.13_ybdqk7g6d4i3uz7fuwczigkhzy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZdOhVZavTx from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.2_encoding@0.1.13_ybdqk7g6d4i3uz7fuwczigkhzy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_efZPO0zsCD from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.2_encoding@0.1.13_ybdqk7g6d4i3uz7fuwczigkhzy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BK8Kv01OcY from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.18.0_typescript@5.6.2_vue@3.5.9_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/void-zero/clients/mijn-epd/.nuxt/components.plugin.mjs";
import prefetch_client_pAZ9ajhNUQ from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.2_encoding@0.1.13_ybdqk7g6d4i3uz7fuwczigkhzy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/void-zero/clients/mijn-epd/.nuxt/pwa-icons-plugin.ts";
import pwa_client_50r1XmnzjA from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.18.0_vite@5.4._gp7wdv2w5g4jm2m62z7o6hztiy/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_6Ph1iQzNHk from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_0XIhYEfg2L from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/@nuxt+icon@1.5.5_magicast@0.3.5_rollup@4.18.0_vite@5.4.8_@types+node@22.7.2_sass@1.79.3_terse_osjnggwjiu7exa2kpr4k3b4jwy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_T68l77SnsG from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.18.0_vue@3.5.9_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_OfSV4tUCLy from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.18.0_vue@3.5.9_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import titles_V7nkKszEcH from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.18.0_vite@5.4.8_@types+node@22.7.2__5ld2po67q6aeaqhzghfv3q3xx4/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_vRgnjrLmVy from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.18.0_vite@5.4.8_@types+node@22.7.2__5ld2po67q6aeaqhzghfv3q3xx4/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_cJeccm0dWt from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.18.0_vite@5.4.8_@types+node@22.7.2_sass@1._w6y42o2nwr4uiqlgfa244mig44/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_DyV8fOvFbg from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.18.0_vite@5.4.8_@types+node@22.7.2_sass@1._w6y42o2nwr4uiqlgfa244mig44/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import session_client_4RPHTklFlm from "/builds/void-zero/clients/mijn-epd/node_modules/.pnpm/nuxt-auth-utils@0.3.9_magicast@0.3.5_rollup@4.18.0_webpack-sources@3.2.3/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
export default [
  revive_payload_client_7jS4uL694P,
  unhead_EtG5wnnFqT,
  router_gbQjmv19h6,
  _0_siteConfig_dDebdgUmgI,
  payload_client_wmaNbmF88K,
  navigation_repaint_client_fmqO5o7ErX,
  check_outdated_build_client_ZdOhVZavTx,
  chunk_reload_client_efZPO0zsCD,
  plugin_vue3_BK8Kv01OcY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pAZ9ajhNUQ,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_50r1XmnzjA,
  plugin_6Ph1iQzNHk,
  plugin_0XIhYEfg2L,
  switch_locale_path_ssr_T68l77SnsG,
  i18n_OfSV4tUCLy,
  titles_V7nkKszEcH,
  defaultsWaitI18n_vRgnjrLmVy,
  siteConfig_cJeccm0dWt,
  inferSeoMetaPlugin_DyV8fOvFbg,
  session_client_4RPHTklFlm
]